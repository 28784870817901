<template>
  <footer
    class="mt-12 border-t-2 pt-12 bg-gray-50 border-gray-100 dark:border-gray-700 dark:bg-gray-800"
  >
    <UContainer>
      <div class="mb-12">
        <div class="grid grid-cols-12 gap-3">
          <div class="col-span-12 md:col-span-4">
            <p class="font-bold mb-4">Kungfutech</p>
            <div class="flex flex-col gap-1">
              <ULink
                to="/"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.home() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="/khoa-hoc"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.course() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="/phong-van-pro/full-stack-web-and-mobile/java"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.interview_questions() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="/posts"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Post
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
            </div>
          </div>
          <div class="col-span-12 md:col-span-4">
            <p class="font-bold mb-4">{{ LL.social_network() }}</p>
            <div class="flex flex-col gap-1">
              <ULink
                to="https://www.youtube.com/c/TechMely?sub_confirmation=1"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Youtube
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://www.tiktok.com/@techmely"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Tiktok
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://www.facebook.com/techmely"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Facebook
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://github.com/techmely"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Github
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
            </div>
          </div>
          <div class="col-span-12 md:col-span-4">
            <p class="font-bold mb-4">{{ LL.community() }}</p>
            <div class="flex flex-col gap-1">
              <ULink
                to="https://www.facebook.com/groups/timviecfe/"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.find_frontend_jobs() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://www.facebook.com/groups/timviecbackend"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.find_backend_jobs() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://discord.com/invite/PGERaWD4CQ"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                Discord
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
              <ULink
                to="https://docs.google.com/forms/d/e/1FAIpQLScIYlkHgiL2MuHXf6YWeOqo09AktHI3R6PL-mSOr122lRuvyA/viewform?usp=sf_link"
                target="_blank"
                class="text-sm font-light flex gap-1 items-center"
                active-class="text-gray-600 dark:text-gray-400"
                inactive-class="text-gray-500 hover:text-gray-700"
              >
                {{ LL.product_feedback() }}
                <UIcon name="i-heroicons-arrow-up-right" class="h-2 w-2" />
              </ULink>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <p class="text-sm mb-4 text-center">@ {{ currYear }} Copyright</p>
        <a
          rel="noopener"
          target="_blank"
          href="//www.dmca.com/Protection/Status.aspx?ID=fcdb03ac-5058-4df7-9efd-7fe5f2a20420"
          title="DMCA.com Protection Status"
          class="dmca-badge"
        >
          <ImagePreset
            data-src="https://images.dmca.com/Badges/dmca_protected_sml_120l.png?ID=fcdb03ac-5058-4df7-9efd-7fe5f2a20420"
            src="/images/common/dmca-protected.png"
            width="121"
            height="24"
            class="pl-2.5"
            alt="DMCA.com Protection Status"
          />
        </a>
      </div>
    </UContainer>
  </footer>
</template>

<script lang="ts" setup>
import { typesafeI18n } from '~/locales/i18n-vue'

const currYear = new Date().getFullYear()

const { LL } = typesafeI18n()
</script>
